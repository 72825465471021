import * as React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
// import Header from "../components/header"

const Privacy = () => (
  <div className="abtus">
  <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Privacy Policy | Coinsclone</title>
        <meta name="description" content="Information that we collect and how we use it at coinsclone.com respects your privacy and is committed to protect the information you provide us through this website." />
        <link rel="canonical" href="https://www.coinsclone.com/privacy-policy/" />
      </Helmet>
      {/* <Header /> */}
    <section className="banner terms">
      <div className="container">
        <div className="row table-content">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <h1 className="main-heading">Privacy Policy</h1>
                <p className="pharagraph">Information that we collect and how we use it at coinsclone.com respects your privacy and is committed to protect the information you provide us through this website. We do not sell or distribute user information to third parties unless it is legally required. We gather user information in order to serve your needs and respond to your information requests.</p>
                
                <h2 className="sub-heading">Data Security Compliance:</h2>
                <p className="pharagraph">The security and privacy of your data is a crucial part of coinsclone.com business. This statement provides our clients, the assurance and our commitment to information & data security compliance. We are committed to respect your online privacy and recognize your need for appropriate protection and management of any personally identifiable information ("Personal Information") you share with us. </p>

                <h2 className="sub-heading">Collecting User Information:</h2>
                <p className="pharagraph">We collect information about our web visitors indirectly through our Internet access logs. When you access coinsclone.com, the browser’s domain name and Internet address is automatically collected and placed in our Internet access logs. We use this information to learn about which sections, pages and information website visitor’s access.</p>

                <h3 className="sub-heading">Usage of Personal Information: </h3>
                <p className="pharagraph">Personal Information means any information that may be used to identify an individual, including, but not limited to, a first and last name, a home or other physical address and an email address or other contact information, whether at work or at home. In general, you can visit coinsclone.com Web pages without telling us who you are or revealing any Personal Information about yourself. If you choose to provide us with your Personal Information on the web, we may transfer that Information within coinslcone.com or to third-party service providers, across borders, and from your country or jurisdiction to other countries or jurisdictions around the world. coinslcone.com strives to comply with all applicable laws around the globe that are designed to protect your privacy. Although legal requirements may vary from country to country,  we intends to adhere to the principles set forth in this Online Privacy Policy even if, in connection with the above, we transfer your Personal Information from your country to countries that may not require an "adequate" level of protection for your Personal Information. In other words, our goal is to provide protection for your Personal Information no matter where that Personal Information is collected, transferred, or retained.</p>
                
                <h3 className="sub-heading">Cookies:  </h3>
                <p className="pharagraph">We may use Web to assist in producing overall site visitor statistics. Cookies, by themselves, cannot be used to find out the identity of any user unless they specifically tell us who they are. If you wish to, you can disable cookies on your computer by changing the settings in preferences or options menu in your browser.!</p>

                <h3 className="sub-heading">Disclosure of User Information: </h3>
                <p className="pharagraph">We does not rent, sell, or share personal information about you with non-affiliated party or companies. If you have submitted user information to us through an e-mail or forms in our website, we maintains your security by ensuring that the information is only distributed within the coinsclone.com Group who are all responsible for responding to your requests either directly or indirectly.</p>
                <p className="pharagraph">We may disclose information in the following circumstances</p>
                <p className="pharagraph">We provide the information to trusted partners who work on behalf of or with coinsclone.com under extremely strict confidentiality agreements.</p>
                <p className="pharagraph">We respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims.</p>
                <p className="pharagraph">We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or as otherwise required by law.</p>
          </div>
        </div>
      </div>
    </section>
  </Layout> 
  </div>
)

export default Privacy
